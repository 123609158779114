import * as React from 'react';
import { fastObjectShallowCompare } from '@mui/x-internals/fastObjectShallowCompare';
import { warnOnce } from '@mui/x-internals/warning';
import { useLazyRef } from "./useLazyRef.js";
import { useOnMount } from "./useOnMount.js";
function isOutputSelector(selector) {
  return selector.acceptsApiRef;
}
// TODO v8: Remove this function
function applySelector(apiRef, selector) {
  if (isOutputSelector(selector)) {
    return selector(apiRef);
  }
  return selector(apiRef.current.state);
}

// TODO v8: Rename this function to `applySelector`
function applySelectorV8(apiRef, selector, args, instanceId) {
  if (isOutputSelector(selector)) {
    return selector(apiRef, args);
  }
  return selector(apiRef.current.state, instanceId);
}
const defaultCompare = Object.is;
export const objectShallowCompare = fastObjectShallowCompare;
const arrayShallowCompare = (a, b) => {
  if (a === b) {
    return true;
  }
  return a.length === b.length && a.every((v, i) => v === b[i]);
};
export const argsEqual = (prev, curr) => {
  let fn = Object.is;
  if (curr instanceof Array) {
    fn = arrayShallowCompare;
  } else if (curr instanceof Object) {
    fn = objectShallowCompare;
  }
  return fn(prev, curr);
};
const createRefs = () => ({
  state: null,
  equals: null,
  selector: null,
  args: null
});

// TODO v8: Remove this function
export const useGridSelector = (apiRef, selector, equals = defaultCompare) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!apiRef.current.state) {
      warnOnce(['MUI X: `useGridSelector` has been called before the initialization of the state.', 'This hook can only be used inside the context of the grid.']);
    }
  }
  const refs = useLazyRef(createRefs);
  const didInit = refs.current.selector !== null;
  const [state, setState] = React.useState(
  // We don't use an initialization function to avoid allocations
  didInit ? null : applySelector(apiRef, selector));
  refs.current.state = state;
  refs.current.equals = equals;
  refs.current.selector = selector;
  useOnMount(() => {
    return apiRef.current.store.subscribe(() => {
      const newState = applySelector(apiRef, refs.current.selector);
      if (!refs.current.equals(refs.current.state, newState)) {
        refs.current.state = newState;
        setState(newState);
      }
    });
  });
  return state;
};

// TODO v8: Rename this function to `useGridSelector`
export const useGridSelectorV8 = (apiRef, selector, args = undefined, equals = defaultCompare) => {
  if (process.env.NODE_ENV !== 'production') {
    if (!apiRef.current.state) {
      warnOnce(['MUI X: `useGridSelector` has been called before the initialization of the state.', 'This hook can only be used inside the context of the grid.']);
    }
  }
  const refs = useLazyRef(createRefs);
  const didInit = refs.current.selector !== null;
  const [state, setState] = React.useState(
  // We don't use an initialization function to avoid allocations
  didInit ? null : applySelectorV8(apiRef, selector, args, apiRef.current.instanceId));
  refs.current.state = state;
  refs.current.equals = equals;
  refs.current.selector = selector;
  const prevArgs = refs.current.args;
  refs.current.args = args;
  if (didInit && !argsEqual(prevArgs, args)) {
    const newState = applySelectorV8(apiRef, refs.current.selector, refs.current.args, apiRef.current.instanceId);
    if (!refs.current.equals(refs.current.state, newState)) {
      refs.current.state = newState;
      setState(newState);
    }
  }
  useOnMount(() => {
    return apiRef.current.store.subscribe(() => {
      const newState = applySelectorV8(apiRef, refs.current.selector, refs.current.args, apiRef.current.instanceId);
      if (!refs.current.equals(refs.current.state, newState)) {
        refs.current.state = newState;
        setState(newState);
      }
    });
  });
  return state;
};