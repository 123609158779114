import { CloudDoneRounded, MenuRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssessmentKebabMenu } from 'src/components/AssessmentKebabMenu';
import { AppLogo, AppOrganizationSwitcher } from 'src/components/common';
import { useRoleCheck, useTenantId } from 'src/hooks';
import { appPrimaryNavigationStrings, commonStrings } from 'src/languages/en-UK';
import { useGetAssessmentQuery } from 'src/services/farmApi';
import {
  ROUTE_PATH_ASSESSMENT_ARCHIVE,
  ROUTE_PATH_DASHBOARD,
  ROUTE_PATH_EVIDENCE_ARCHIVE,
} from 'src/settings';

import { MobileCompatibleTooltip } from '../MobileCompatibleTooltip';
import { type RouteDefinition } from '../types/RouteDefinition';

const DRAWER_WIDTH = 320;

interface AppPrimaryNavigationProps {
  routes: RouteDefinition[];
}

export const AppPrimaryNavigation = ({ routes }: AppPrimaryNavigationProps): React.JSX.Element => {
  const tid = useTenantId();
  const navigate = useNavigate();
  const location = useLocation();
  const topLevelRoute = `/${location.pathname.split('/')[1]}`;
  // Find the assessment name
  const assessmentId =
    topLevelRoute === ROUTE_PATH_ASSESSMENT_ARCHIVE ? location.pathname.split('/')[2] : undefined;
  let assessmentDetail;
  const { data: assessment, isLoading } = useGetAssessmentQuery(
    !assessmentId || !tid ? skipToken : { tid, id: assessmentId },
  );
  if (assessment && !isLoading) {
    assessmentDetail = assessment;
  } else {
    assessmentDetail = undefined;
  }
  const [isOpen, setIsOpen] = useState(false);
  const { isCB, isFarm, isStandardSetter } = useRoleCheck();
  const showAssessmentName = !!assessmentId && !isLoading && !!assessmentDetail?.name;

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  // Prevent re-renders by currying navigation handler
  const routesWithNavigation = routes.map((route) => ({
    ...route,
    handleNavigate: () => {
      if (isOpen) {
        closeDrawer();
      }
      navigate(route.path);
    },
  }));

  return (
    <>
      <Box
        data-cy="app-primary-navigation"
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: { xs: 0, sm: 1.25 },
        }}
      >
        {routes.length > 0 && (
          <IconButton
            color="inherit"
            edge={false}
            onClick={toggleDrawer}
            sx={{ display: { lg: 'none' }, pl: { xs: 0, sm: 1 }, py: { xs: 1.5, sm: 1 } }}
          >
            <MenuRounded />
          </IconButton>
        )}
        <AppLogo showSmallLogo />
        <Box
          sx={{
            alignItems: 'center',
            display: { xs: 'none', lg: 'flex' },
          }}
        >
          {routesWithNavigation.map((route) => {
            let headerTitle = route.name;
            if ((isCB || isStandardSetter) && route.path === ROUTE_PATH_ASSESSMENT_ARCHIVE) {
              headerTitle = commonStrings('inspections');
            }
            if (isFarm && route.path === ROUTE_PATH_DASHBOARD) {
              headerTitle = appPrimaryNavigationStrings('overview');
            }
            if (isStandardSetter && route.path === ROUTE_PATH_EVIDENCE_ARCHIVE) {
              headerTitle = commonStrings('evidence');
            }
            if (
              (isCB && route.path === ROUTE_PATH_EVIDENCE_ARCHIVE) ||
              (isStandardSetter && route.path === ROUTE_PATH_DASHBOARD)
            ) {
              return null;
            }

            return (
              <Button
                key={route.path}
                onClick={route.handleNavigate}
                sx={{
                  color: topLevelRoute === route.path ? 'text.primary' : 'text.secondary',
                  display: 'block',
                  fontWeight: topLevelRoute === route.path ? 600 : 400,
                  textTransform: 'none',
                  letterSpacing: 0.4,
                  ml: 2,
                  height: 64,
                  borderRadius: 0,
                  boxSizing: 'border-box',
                  pt: 1.25,
                  transition: 'border-color 0.25s linear',
                }}
              >
                {headerTitle}
              </Button>
            );
          })}
        </Box>
      </Box>
      {showAssessmentName && (
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            display: { xs: 'none', lg: 'flex' },
            gap: 1,
            px: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              textAlign: 'center',
            }}
            variant="caption"
          >
            {assessment?.name}
          </Typography>
          <MobileCompatibleTooltip
            placement="right"
            title={appPrimaryNavigationStrings('allProviderAnswersWereAutosaved')}
          >
            <CloudDoneRounded color="disabled" />
          </MobileCompatibleTooltip>
          {isCB && <AssessmentKebabMenu assessmentDetail={assessmentDetail} />}
        </Stack>
      )}
      {routes.length > 0 && (
        <Drawer
          data-cy="app-primary-navigation"
          onClose={closeDrawer}
          open={isOpen}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
            zIndex: 1200,
          }}
        >
          <Toolbar>
            <AppLogo
              showSmallLogo
              sx={{ mt: 5, mb: 1.5 }}
            />
          </Toolbar>
          <List sx={{ pt: 0, pb: 0 }}>
            {routesWithNavigation.map((route) => {
              let headerTitle = route.name;
              if ((isCB || isStandardSetter) && route.path === ROUTE_PATH_ASSESSMENT_ARCHIVE) {
                headerTitle = commonStrings('inspections');
              }
              if (isFarm && route.path === ROUTE_PATH_DASHBOARD) {
                headerTitle = appPrimaryNavigationStrings('overview');
              }
              if (isStandardSetter && route.path === ROUTE_PATH_EVIDENCE_ARCHIVE) {
                headerTitle = commonStrings('evidence');
              }
              if (
                (isCB && route.path === ROUTE_PATH_EVIDENCE_ARCHIVE) ||
                (isStandardSetter && route.path === ROUTE_PATH_DASHBOARD)
              ) {
                return null;
              }

              return (
                <ListItemButton
                  key={route.path}
                  onClick={route.handleNavigate}
                  sx={{
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  {route.icon && <ListItemIcon>{route.icon}</ListItemIcon>}
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      letterSpacing: 0.1,
                      textTransform: 'none',
                    }}
                    variant="button"
                  >
                    {headerTitle}
                  </Typography>
                </ListItemButton>
              );
            })}
          </List>
          <Stack
            sx={{
              display: { xs: 'flex', lg: 'none' },
              pb: 1.5,
              pt: 3.5,
              px: 2,
            }}
          >
            {showAssessmentName && (
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'space-between',
                  pb: 1.5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                  }}
                  variant="caption"
                >
                  {assessment?.name}
                </Typography>
                <MobileCompatibleTooltip
                  slotProps={{
                    popper: { disablePortal: true },
                  }}
                  title={appPrimaryNavigationStrings('allProviderAnswersWereAutosaved')}
                >
                  <CloudDoneRounded color="disabled" />
                </MobileCompatibleTooltip>
                {isCB && <AssessmentKebabMenu assessmentDetail={assessmentDetail} />}
              </Stack>
            )}
            <AppOrganizationSwitcher inlineMenu />
          </Stack>
        </Drawer>
      )}
    </>
  );
};
