import { DownloadRounded } from '@mui/icons-material';
import { Alert, Box, Button, type SxProps, useTheme } from '@mui/material';
import { DocumentEditor } from '@onlyoffice/document-editor-react';
import { type SimpleFile } from 'src/__generated__/InternalApiTypes';
import { useAppDispatch } from 'src/hooks';
import { commonStrings, documentPreviewStrings } from 'src/languages/en-UK';
import { ONLY_OFFICE_SERVER_URL } from 'src/settings';
import { openSnackbar } from 'src/store/snackbarSlice';
import { downloadFileToLocal, getFileExtension } from 'src/utils/fileUtils';

import { LoadingArea } from '../LoadingArea';

const fullySupportedEditingFileExtensions = [
  'docm',
  'docx',
  'dotm',
  'dotx',
  'xlsm',
  'xlsx',
  'xltm',
  'xltx',
  'potm',
  'potx',
  'ppsm',
  'ppsx',
  'pptm',
  'pptx',
  'txt',
  'csv',
];

const partiallySupportedEditingFileExtensions = [
  'doc',
  'xls',
  'epub',
  'fb2',
  'html',
  'odt',
  'ott',
  'rtf',
  'csv',
  'ods',
  'ots',
  'odp',
  'otp',
];

export const supportedEditingFileExtensions = [
  ...fullySupportedEditingFileExtensions,
  ...partiallySupportedEditingFileExtensions,
];

const supportedNonEditingFileExtensions = ['pdf'];

const onlyOfficeSupportedFileExtensions = [
  ...supportedEditingFileExtensions,
  ...supportedNonEditingFileExtensions,
];

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#supported_image_formats
export const imageFileExtensions = [
  'bmp',
  'png',
  'jpg',
  'jpeg',
  'apng',
  'avif',
  'gif',
  'svg',
  'webp',
];

export const allSupportedFileExtensions = [
  ...onlyOfficeSupportedFileExtensions,
  ...imageFileExtensions,
];

interface DocumentPreviewProps {
  id: string | undefined;
  title: string;
  url: string | undefined;
  isEditable?: boolean;
  isLoading?: boolean;
  sx?: SxProps;
}

export const DocumentPreview = ({
  id,
  title,
  url,
  isEditable = false,
  isLoading = false,
  sx = {},
}: DocumentPreviewProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  if (!url || !title || isLoading) {
    return <LoadingArea />;
  }

  const fileType = getFileExtension(url);
  const isImage = imageFileExtensions.includes(fileType);
  const isOnlyOfficeSupported = onlyOfficeSupportedFileExtensions.includes(fileType);
  const isUnsupportedFileType = !isImage && !isOnlyOfficeSupported;

  const borderStyle = {
    borderColor: theme?.palette?.divider,
    borderStyle: 'solid',
    borderWidth: 1,
  };

  const handleDownload = (): void => {
    if (id) {
      const simpleFile: SimpleFile = {
        file_object: url,
        name: title,
        uuid: id,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
      };

      downloadFileToLocal(simpleFile, title, (msg) =>
        dispatch(openSnackbar({ message: msg, severity: 'error' })),
      );
    }
  };

  const onlyOfficeWebhookUrl = `${window.appConfig.onlyOfficeWebhookUrl}${window.appConfig.onlyOfficeWebhookUrl.slice(-1) !== '/' ? '/' : ''}evidence/only-office-webhook/`;
  return (
    <Box
      sx={{
        ...(isImage
          ? {
              ...borderStyle,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }
          : {}),
        borderRadius: `${theme?.shape?.borderRadius || 4}px`,
        flex: '1 1 auto',
        height: 'calc(90vh - 156px)',
        overflow: 'hidden',
        width: '100%',
        ...sx,
      }}
    >
      {isUnsupportedFileType && (
        <div>
          <Alert
            severity="warning"
            sx={{ mb: 2 }}
          >
            {documentPreviewStrings('fileTypeCannotBePreviewed')}
          </Alert>
          <Button
            component="label"
            onClick={() => handleDownload()}
            role={undefined}
            startIcon={<DownloadRounded />}
            sx={{ float: 'center' }}
            variant="contained"
          >
            {commonStrings('download')}
          </Button>
        </div>
      )}
      {isImage && (
        <img
          alt=""
          src={url}
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
      )}
      {isOnlyOfficeSupported && id && url && (
        <DocumentEditor
          config={{
            document: {
              fileType,
              url,
              title: `${title}.${fileType}`,
              key: id,
              permissions: {
                chat: false,
                comment: false,
                edit: !!isEditable,
                download: false,
                protect: false,
              },
            },
            documentType: '',
            editorConfig: {
              mode: isEditable ? 'edit' : 'view',
              customization: {
                anonymous: {
                  request: false,
                  label: 'Guest',
                },
                comments: false,
                compactHeader: true,
                compactToolbar: true,
                hideRightMenu: false,
                mentionShare: false,
                unit: 'cm',
                plugins: false,
                help: false,
                zoom: 100,
              },
              callbackUrl: onlyOfficeWebhookUrl,
            },
          }}
          documentServerUrl={ONLY_OFFICE_SERVER_URL}
          height="100%"
          id="docEditor"
          width="100%"
        />
      )}
    </Box>
  );
};
