import { fetchAuthSession } from '@aws-amplify/auth';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import config from 'src/config';

import { TagType } from './TagType';

export const baseApi = createApi({
  reducerPath: 'farmApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: async (headers: Headers): Promise<Headers> => {
      await fetchAuthSession()
        .then(({ tokens }) => {
          headers.set('authorization', `Bearer ${tokens?.idToken}`);
          headers.set('Accept-Language', localStorage.getItem('selectedLanguage') ?? 'en');
        })
        .catch((error: string) => {
          if (error === 'No current user') {
            return; // Ignore this, requests will be sent as anonymous
          }
          // eslint-disable-next-line no-console
          console.error(error);
        });
      return headers;
    },
  }),
  tagTypes: Object.values(TagType),
  endpoints: () => ({}),
});
