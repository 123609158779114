import {
  type OrganizationCreateInput,
  type OrganizationCreateOutput,
  type OrganizationDetailOutput,
  type OrganizationUpdateInput,
  type OrganizationUpdateOutput,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { type DetailParamsWithTenantId, type UpdateParamsWithTenantId } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'organizations';

const organizationEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganization: build.query<OrganizationDetailOutput, DetailParamsWithTenantId>({
      query: ({ tid, id }) => `/tid/${tid}/${RESOURCE}/${id}/`,
      providesTags: [TagType.Organization, TagType.Assessment],
    }),

    createOrganization: build.mutation<OrganizationCreateOutput, Partial<OrganizationCreateInput>>({
      query: (body) => ({
        url: `/${RESOURCE}/create/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        // When creating an organization, it may change a user's membership, so refetch that too
        TagType.UserProfile,
        TagType.Organization,
      ],
    }),

    updateOrganization: build.mutation<
      OrganizationUpdateOutput,
      UpdateParamsWithTenantId<OrganizationUpdateInput>
    >({
      query: ({ id, tid, body }) => ({
        url: `/tid/${tid}/${RESOURCE}/${id}/update/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        // When updating an organization, it may change a user's membership, so refetch that too
        TagType.UserProfile,
        TagType.Organization,
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} = organizationEndpoints;
