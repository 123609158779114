import {
  BrowserUpdatedRounded,
  DashboardRounded,
  LanRounded,
  ListAltRounded,
  ManageAccountsRounded,
  QuestionAnswerRounded,
} from '@mui/icons-material';
import { type RouteDefinition } from 'src/components/common';
import { PreventAuth } from 'src/components/PreventAuth';
import { RequireAuth } from 'src/components/RequireAuth';

import { commonStrings, routesHeaderStrings } from './languages/en-UK';
import { AccountPage } from './pages/AccountPage';
import { AssessmentArchivePage } from './pages/AssessmentArchivePage';
import { AssessmentPage } from './pages/AssessmentPage';
import { AuthPage } from './pages/AuthPage';
import { ChangeTemporaryPasswordPage } from './pages/ChangeTemporaryPasswordPage';
import { CreateOrganizationPage } from './pages/CreateOrganizationPage';
import { DashboardPage } from './pages/DashboardPage';
import { DashboardRequestPage } from './pages/DashboardRequestPage';
import { EvidenceTabsPage } from './pages/EvidenceTabsPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { OrganizationPage } from './pages/OrganizationPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { SignUpVerificationPage } from './pages/SignUpVerificationPage';
import { SplashPage } from './pages/SplashPage';
import { getMobileOperatingSystem } from './pages/SplashPage/SplashPage.utils';

export const DATE_DISPLAY_FORMAT = 'dd/MM/yyyy';
export const DATE_DISPLAY_FORMAT_FOR_TABLE = 'yyyy/MM/dd';
export const DATE_TIME_DISPLAY_FORMAT = 'dd/MM/yyyy HH:mm';
export const DATE_TIME_DISPLAY_FORMAT_FOR_TABLE = 'yyyy/MM/dd HH:mm';
export const DATE_DISPLAY_SERVER_FORMAT = 'dd-MM-yyyy';
export const DATE_SERVER_FORMAT = 'yyyy-MM-dd';

export const FILE_TYPE_PDF = 'pdf';

export const PASSWORD_REG_EX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*_\-+=|;:"<>,.?]).{8,}/;
export const PHONE_REG_EX =
  /^\+((\\+[1-9]{1,4}[ \\-]*)|(\\(\d{2,3}\\)[ \\-]*)|(\d{2,4})[ \\-]*)*?\d{3,4}?[ \\-]*\d{3,4}$/;
export const URL_REG_EX =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[\w-]+(\.[\w-]+)+(\/)?.([\w[.\-%/@]+)*([\w?[-]+=\w+(&[\w-]+=[\w%]+)*)?$/;

export const ROUTE_PATH_ACCOUNT = '/account';
export const ROUTE_PATH_ACCOUNT_INVITATIONS = '/account?tab=invitations';
export const ROUTE_PATH_ORGANIZATION = '/organization';
export const ROUTE_PATH_CREATE_ORGANIZATION = '/organization/create';
export const ROUTE_PATH_CREATE_MORE_ORGANIZATION = '/organization/create-more';
export const ROUTE_PATH_CREATE_ORGANIZATION_USERS = '/organization/create?tab=users';
export const ROUTE_PATH_CREATE_ORGANIZATION_CLIENT_IDS = '/organization/create?tab=id-numbers';
export const ROUTE_PATH_ORGANIZATION_USERS = '/organization?tab=users';
export const ROUTE_PATH_ORGANIZATION_PRODUCTS = '/organization?tab=products';
export const ROUTE_PATH_ORGANIZATION_PLOTS = '/organization?tab=plots';

export const ROUTE_PATH_DASHBOARD = '/dashboard';
export const ROUTE_PATH_DASHBOARD_REQUESTS = '/dashboard/requests';
export const ROUTE_PATH_ASSESSMENT_ARCHIVE = '/assessments';
export const ROUTE_PATH_ASSESSMENT = '/assessments/:id';
export const ROUTE_PATH_EVIDENCE_ARCHIVE = '/evidence';

// Account & Authentication
export const ROUTE_PATH_FORGOT_PASSWORD = '/forgot-password';
export const ROUTE_PATH_LOG_IN = '/login';
export const ROUTE_PATH_SPLASH = '/';
export const ROUTE_PATH_CHANGE_TEMPORARY_PASSWORD = '/change-temporary-password';
const ROUTE_PATH_RESET_PASSWORD = '/reset-password';
export const ROUTE_PATH_SIGN_UP_VERIFICATION = '/verify';

export const ROUTE_PATH_PRIVACY_POLICY = 'https://chain.agriplace.com/privacy-policy';
export const ROUTE_PATH_TERMS_OF_SERVICE = 'https://chain.agriplace.com/terms-of-service';
export const ROUTE_PATH_PRIVACY_POLICY_SMK = 'https://www.smk.nl/en/disclaimer-and-privacy/';
export const ROUTE_MALUS_POINT_EXPLANATION_LINK =
  'https://www.notion.so/agriplace/How-to-calculate-malus-points-b2ec44ba1231456b87d89493951594c6';

// TODOHasan: We must replace this link with leaf one
export const ROUTE_PATH_PRIVACY_POLICY_LEAF = 'https://chain.agriplace.com/privacy-policy';

// TODOHasan: We must replace this link with leaf one
export const ROUTE_PATH_TERMS_OF_SERVICE_LEAF = 'https://chain.agriplace.com/terms-of-service';
export const REVIEW_MAIL_ADDRESS_LEAF = 'review@leafuk.org';
export const MEMBER_SUPPORT_MAIL_ADDRESS_LEAF = 'members@leaf.eco';

export const ONLY_OFFICE_SERVER_URL = 'https://prod.onlyoffice.agriplace.com/';
export const ZOHO_SERVER_URL = 'https://social20062912198.zohodesk.eu/portal/en/home';

const [isMobile] = getMobileOperatingSystem();
const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

export const ROUTES: RouteDefinition[] = [
  {
    name: commonStrings('dashboard'),
    path: ROUTE_PATH_DASHBOARD,
    icon: <DashboardRounded />,
    element: (
      <RequireAuth>
        <DashboardPage />
      </RequireAuth>
    ),
    visibility: ['header'],
  },
  {
    name: commonStrings('documents'),
    path: ROUTE_PATH_EVIDENCE_ARCHIVE,
    icon: <BrowserUpdatedRounded />,
    element: (
      <RequireAuth>
        <EvidenceTabsPage />
      </RequireAuth>
    ),
    visibility: ['header'],
  },
  {
    name: commonStrings('selfAssessments'),
    path: ROUTE_PATH_ASSESSMENT_ARCHIVE,
    icon: <ListAltRounded />,
    element: (
      <RequireAuth>
        <AssessmentArchivePage />
      </RequireAuth>
    ),
    visibility: ['header'],
  },
  {
    name: routesHeaderStrings('assessmentAsComponent'),
    path: ROUTE_PATH_ASSESSMENT,
    icon: <QuestionAnswerRounded />,
    element: (
      <RequireAuth>
        <AssessmentPage />
      </RequireAuth>
    ),
    visibility: [],
  },
  {
    name: routesHeaderStrings('myAccount'),
    path: ROUTE_PATH_ACCOUNT,
    icon: <ManageAccountsRounded />,
    element: (
      <RequireAuth>
        <AccountPage />
      </RequireAuth>
    ),
    visibility: ['userMenu'],
  },
  {
    name: routesHeaderStrings('organizationProfileAsComponent'),
    path: ROUTE_PATH_ORGANIZATION,
    icon: <LanRounded />,
    element: (
      <RequireAuth>
        <OrganizationPage />
      </RequireAuth>
    ),
    visibility: ['userMenu'],
  },
  {
    name: commonStrings('logIn'),
    path: ROUTE_PATH_LOG_IN,
    element: (
      <PreventAuth>
        <AuthPage />
      </PreventAuth>
    ),
    visibility: [],
  },
  {
    name: isMobile && !isStandalone ? '' : commonStrings('logIn'),
    path: ROUTE_PATH_SPLASH,
    element: <PreventAuth>{isMobile && !isStandalone ? <SplashPage /> : <AuthPage />}</PreventAuth>,
    visibility: [],
  },
  {
    name: commonStrings('forgotPassword'),
    path: ROUTE_PATH_FORGOT_PASSWORD,
    element: (
      <PreventAuth>
        <ForgotPasswordPage />
      </PreventAuth>
    ),
    visibility: [],
  },
  {
    name: routesHeaderStrings('resetYourPassword'),
    path: ROUTE_PATH_CHANGE_TEMPORARY_PASSWORD,
    element: (
      <PreventAuth>
        <ChangeTemporaryPasswordPage />
      </PreventAuth>
    ),
    visibility: [],
  },
  {
    name: commonStrings('resetPassword'),
    path: ROUTE_PATH_RESET_PASSWORD,
    element: (
      <PreventAuth>
        <ResetPasswordPage />
      </PreventAuth>
    ),
    visibility: [],
  },
  {
    name: routesHeaderStrings('signUpVerification'),
    path: ROUTE_PATH_SIGN_UP_VERIFICATION,
    element: (
      <PreventAuth>
        <SignUpVerificationPage />
      </PreventAuth>
    ),
    visibility: [],
  },
  {
    name: routesHeaderStrings('createOrganization'),
    path: ROUTE_PATH_CREATE_ORGANIZATION,
    element: (
      <RequireAuth>
        <CreateOrganizationPage />
      </RequireAuth>
    ),
    visibility: [],
  },
  {
    name: routesHeaderStrings('createOrganization'),
    path: ROUTE_PATH_CREATE_MORE_ORGANIZATION,
    element: (
      <RequireAuth>
        <CreateOrganizationPage />
      </RequireAuth>
    ),
    visibility: [],
  },
  {
    name: commonStrings('requests'),
    path: ROUTE_PATH_DASHBOARD_REQUESTS,
    element: (
      <RequireAuth>
        <DashboardRequestPage />
      </RequireAuth>
    ),
    visibility: [],
  },
];

export const DEFAULT_DATA_GRID_PAGINATION = [10, 25, 50];
export const DEFAULT_DATA_GRID_STATE = {
  columns: {
    columnVisibilityModel: {
      id: false,
      uuid: false,
    },
  },
  pagination: {
    paginationModel: { pageSize: DEFAULT_DATA_GRID_PAGINATION[1] },
  },
};

export const ASSESSMENT_CONTROL_POINT_PAGINATION = {
  page_size: DEFAULT_DATA_GRID_PAGINATION[1],
};
