import {
  type ControlPointProductionTechniqueListOutputItem,
  type ControlPointProductionTechniquesListParams,
} from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { injectQueryParams } from '../helpers';
import { TagType } from '../TagType';

const RESOURCE = 'control-point-production-techniques';

const controlPointProductionTechniqueEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getControlPointProductionTechniqueList: build.query<
      ControlPointProductionTechniqueListOutputItem[],
      ControlPointProductionTechniquesListParams
    >({
      query: (params) => ({
        url: injectQueryParams(`/${RESOURCE}/`, params),
      }),
      providesTags: [TagType.ControlPointProductionTechnique],
    }),
  }),
  overrideExisting: false,
});

export const { useGetControlPointProductionTechniqueListQuery } =
  controlPointProductionTechniqueEndpoints;
