import { skipToken } from '@reduxjs/toolkit/query';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type CamundaProcessDefinitionListOutputItem,
  RolesEnum,
} from 'src/__generated__/InternalApiTypes';
import { TenantContext } from 'src/components/TenantProvider';
import { useTenantId, useUserAuth } from 'src/hooks';
import {
  useCompleteCamundaTaskMutation,
  useGetAssessmentQuery,
  useGetCamundaProcessDefinitionListQuery,
  useGetCamundaProcessInstanceByAssessmentIdQuery,
  useGetCamundaTaskDefinitionListQuery,
  useGetCamundaTaskListQuery,
} from 'src/services/farmApi';

export const useCamundaState = (assessmentId?: string | null) => {
  const tid = useTenantId();
  const { userId } = useUserAuth();
  const { activeMembership } = useContext(TenantContext);
  const navigate = useNavigate();
  const skipForAssessmentDetail = !assessmentId || !tid;

  const { data: assessmentDetail } = useGetAssessmentQuery(
    skipForAssessmentDetail ? skipToken : { tid, id: assessmentId },
  );

  const { data: processDefinitionList } = useGetCamundaProcessDefinitionListQuery(tid ?? skipToken);

  const assessmentProcessDefinitions = processDefinitionList?.filter(
    (processDefinition) => processDefinition.workflow_id === assessmentDetail?.workflow_id,
  );

  let processDefinitionKey: string | null = null;
  let processDefinitionName: string | null = null;
  let processDefinitionId: string | null = null;

  if (assessmentProcessDefinitions?.length === 1) {
    processDefinitionKey = assessmentProcessDefinitions?.[0]?.process_definition_key ?? null;
    processDefinitionName = assessmentProcessDefinitions?.[0]?.process_definition_name;
    processDefinitionId = assessmentProcessDefinitions?.[0]?.process_definition_id;
  }

  if (
    assessmentProcessDefinitions &&
    assessmentProcessDefinitions.length > 1 &&
    [RolesEnum.Auditor, RolesEnum.AuditorReviewer, RolesEnum.CertificationBodyManager].some(
      (role) => activeMembership?.roles.includes(role),
    )
  ) {
    let processDefinition: CamundaProcessDefinitionListOutputItem | undefined;
    const isAssessmentAuthor = assessmentDetail?.author_id === userId;
    if (isAssessmentAuthor) {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.Auditor),
      );
    } else if (activeMembership?.roles.includes(RolesEnum.CertificationBodyManager)) {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.CertificationBodyManager),
      );
    } else {
      processDefinition = assessmentProcessDefinitions?.find((assessmentProcessDefinition) =>
        assessmentProcessDefinition?.roles?.includes(RolesEnum.AuditorReviewer),
      );
    }
    processDefinitionKey = processDefinition?.process_definition_key ?? null;
    processDefinitionName = processDefinition?.process_definition_name ?? null;
    processDefinitionId = processDefinition?.process_definition_id ?? null;
  }

  const {
    data: taskDefinitionList,
    isLoading: isTaskDefinitionListLoading,
    isFetching: isTaskDefinitionListFetching,
  } = useGetCamundaTaskDefinitionListQuery(processDefinitionId ?? skipToken);

  const skipForProcessInstance = !tid || !assessmentId || !processDefinitionId;

  const {
    data: processInstance,
    isLoading: isProcessInstanceLoading,
    isFetching: isProcessInstanceFetching,
  } = useGetCamundaProcessInstanceByAssessmentIdQuery(
    skipForProcessInstance
      ? skipToken
      : {
          tid,
          uuid: assessmentId,
          process_definition_id: processDefinitionId as string,
        },
  );

  const [completeTask, completeTaskResult] = useCompleteCamundaTaskMutation();
  const completeCurrentTask = (taskId: string, newTaskSlug: string) => {
    if (tid && assessmentId) {
      completeTask({
        tid,
        id: taskId,
        body: {
          new_task_slug: newTaskSlug,
          assessment_id: assessmentId,
        },
      });
    }
  };

  const skipForCamundaTask = !processInstance?.uuid || !tid;
  const {
    data: taskList,
    isLoading: isTaskListLoading,
    isFetching: isTaskListFetching,
  } = useGetCamundaTaskListQuery(
    skipForCamundaTask ? skipToken : { tid, process_instance_id: processInstance?.uuid },
  );
  const currentTask = completeTaskResult.data || taskList?.[0];

  const currentTaskIndex = taskDefinitionList
    ? taskDefinitionList.findIndex((taskDefinition) => taskDefinition?.slug === currentTask?.slug)
    : -1;
  let nextTaskName = '';
  let nextTaskSlug = '';
  let previousTaskSlug = '';
  if (currentTaskIndex !== -1 && taskDefinitionList) {
    nextTaskName = taskDefinitionList[currentTaskIndex + 1]?.name;
    nextTaskSlug = taskDefinitionList[currentTaskIndex + 1]?.slug;
    previousTaskSlug = taskDefinitionList[currentTaskIndex - 1]?.slug;
  }

  const moveToNextTask = () => {
    if (currentTask?.uuid) {
      completeCurrentTask(currentTask.uuid, nextTaskSlug);
    }
  };

  const moveToPreviousTask = () => {
    if (currentTaskIndex === 0) {
      navigate('/');
      return;
    }
    if (currentTask?.uuid) {
      completeCurrentTask(currentTask.uuid, previousTaskSlug);
    }
  };

  const isFinalTask = currentTaskIndex === (taskDefinitionList?.length ?? 0) - 1;
  const isFirstTask = currentTaskIndex === 0;

  return {
    isCamundaStateLoading:
      isTaskDefinitionListLoading || isProcessInstanceLoading || isTaskListLoading,
    isCamundaStateFetching:
      isTaskDefinitionListFetching || isProcessInstanceFetching || isTaskListFetching,
    isNewTaskLoading: completeTaskResult.isLoading,
    currentTaskSlug: currentTask?.slug,
    currentTaskId: currentTask?.uuid,
    currentTaskName: currentTask?.name,
    processInstanceId: processInstance?.uuid,
    currentTaskIndex,
    nextTaskName,
    nextTaskSlug,
    previousTaskSlug,
    processDefinitionKey,
    processDefinitionName,
    taskDefinitionList,
    completeCurrentTask,
    moveToNextTask,
    moveToPreviousTask,
    isFinalTask,
    isFirstTask,
  };
};
