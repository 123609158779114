import { type SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetUserOrigin } from 'src/hooks';

import { type TabbedPageTabDef } from './TabbedPage.types';

type UseTabsReturnValue = [number, (_e: SyntheticEvent, i: number) => void];

export const useTabs = (tabs: (TabbedPageTabDef | null)[]): UseTabsReturnValue => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');
  const currentTask = searchParams.get('task');
  const redirectPath = searchParams.get('redirect');
  const prefilledEmail = searchParams.get('email');
  const accountType = searchParams.get('accounttype');
  const origin = useGetUserOrigin();
  const targetTab = tabs?.find((tabDef) => tabDef?.slug === currentTab);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const getCurrentSearchParams = useCallback(
    (tabSlug: string) => {
      let navigateSearchParams = `?tab=${tabSlug}`;
      if (currentTask) {
        navigateSearchParams += `&task=${currentTask}`;
      }
      if (origin) {
        navigateSearchParams += `&origin=${origin}`;
      }
      if (prefilledEmail) {
        navigateSearchParams += `&email=${encodeURIComponent(prefilledEmail)}`;
      }
      if (accountType) {
        navigateSearchParams += `&accounttype=${accountType}`;
      }
      if (redirectPath) {
        navigateSearchParams += `&redirect=${redirectPath}`;
      }
      return navigateSearchParams;
    },
    [accountType, currentTask, origin, prefilledEmail, redirectPath],
  );

  useEffect((): void => {
    if (tabs?.length > 0 && targetTab) {
      const targetTabIndex = tabs.indexOf(targetTab);
      if (activeTabIndex !== targetTabIndex) {
        setActiveTabIndex(targetTabIndex);
      }
    }
  }, [activeTabIndex, tabs, targetTab]);

  const handleChangeTab = (_e: SyntheticEvent, i: number) => {
    if (tabs?.[i]?.slug) {
      const search = getCurrentSearchParams(tabs[i].slug);
      navigate(
        {
          search,
          pathname: location.pathname,
        },
        { replace: true },
      );
    }
  };

  return [activeTabIndex, handleChangeTab];
};
