// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { createAssertionFunction } from '../errors';
export var ServiceWorkerErrorCode;
(function (ServiceWorkerErrorCode) {
    ServiceWorkerErrorCode["UndefinedInstance"] = "UndefinedInstance";
    ServiceWorkerErrorCode["UndefinedRegistration"] = "UndefinedRegistration";
    ServiceWorkerErrorCode["Unavailable"] = "Unavailable";
})(ServiceWorkerErrorCode || (ServiceWorkerErrorCode = {}));
const serviceWorkerErrorMap = {
    [ServiceWorkerErrorCode.UndefinedInstance]: {
        message: 'Service Worker instance is undefined.',
    },
    [ServiceWorkerErrorCode.UndefinedRegistration]: {
        message: 'Service Worker registration is undefined.',
    },
    [ServiceWorkerErrorCode.Unavailable]: {
        message: 'Service Worker not available.',
    },
};
export const assert = createAssertionFunction(serviceWorkerErrorMap);
