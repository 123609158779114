import { useGridRefs } from "./useGridRefs.js";
import { useGridIsRtl } from "./useGridIsRtl.js";
import { useGridLoggerFactory } from "./useGridLoggerFactory.js";
import { useGridApiInitialization } from "./useGridApiInitialization.js";
import { useGridLocaleText } from "./useGridLocaleText.js";
import { useGridPipeProcessing } from "./pipeProcessing/index.js";
import { useGridStrategyProcessing } from "./strategyProcessing/index.js";
import { useGridStateInitialization } from "./useGridStateInitialization.js";

/**
 * Initialize the technical pieces of the DataGrid (logger, state, ...) that any DataGrid implementation needs
 */
export const useGridInitialization = (inputApiRef, props) => {
  const privateApiRef = useGridApiInitialization(inputApiRef, props);
  useGridRefs(privateApiRef);
  useGridIsRtl(privateApiRef);
  useGridLoggerFactory(privateApiRef, props);
  useGridStateInitialization(privateApiRef);
  useGridPipeProcessing(privateApiRef);
  useGridStrategyProcessing(privateApiRef);
  useGridLocaleText(privateApiRef, props);
  privateApiRef.current.register('private', {
    rootProps: props
  });
  return privateApiRef;
};