import { ExpandMoreRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  type AccordionProps,
  AccordionSummary,
  Container,
  Typography,
} from '@mui/material';

import * as styles from './ExpandableArea.module.scss';

interface ExpandableAreaProps extends AccordionProps {
  title: string;
  paddingLeft?: number;
}

export const ExpandableArea = ({
  paddingLeft,
  title,
  ...props
}: ExpandableAreaProps): React.JSX.Element => (
  <Accordion
    {...props}
    disableGutters
    elevation={0}
    square
    sx={{
      '&:before': {
        display: 'none',
      },
      '&:not(:first-of-type)': {
        borderBottom: 1,
        borderBottomColor: 'divider',
      },
      '&.MuiAccordion-root.Mui-expanded': {
        overflowAnchor: 'auto',
      },
    }}
  >
    <AccordionSummary
      aria-controls="panel1d-content"
      expandIcon={<ExpandMoreRounded />}
      id="panel1d-header"
      sx={{
        '& .MuiTypography-root': {
          letterSpacing: 0.15,
        },
        '& .MuiContainer-root': {
          pl: paddingLeft ?? 2,
          pr: 1,
        },
      }}
    >
      <Container>
        <Typography>{title}</Typography>
      </Container>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: styles.AccordionDetailsRoot,
      }}
      sx={{
        '& .MuiTypography-root': {
          letterSpacing: 0.15,
        },
      }}
    >
      {props.children}
    </AccordionDetails>
  </Accordion>
);
