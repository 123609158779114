// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { PasskeyErrorCode, assertPasskeyError } from '../errors';
/**
 * Passkey Create Types
 */
export { assertValidCredentialCreationOptions, } from './shared';
export function assertCredentialIsPkcWithAuthenticatorAttestationResponse(credential) {
    assertPasskeyError(credential &&
        credential instanceof PublicKeyCredential &&
        credential.response instanceof AuthenticatorAttestationResponse, PasskeyErrorCode.PasskeyRegistrationFailed);
}
export function assertCredentialIsPkcWithAuthenticatorAssertionResponse(credential) {
    assertPasskeyError(credential &&
        credential instanceof PublicKeyCredential &&
        credential.response instanceof AuthenticatorAssertionResponse, PasskeyErrorCode.PasskeyRetrievalFailed);
}
