import { type ExternalMembershipListOutputItem } from 'src/__generated__/InternalApiTypes';

import { baseApi } from '../baseApi';
import { TagType } from '../TagType';

const RESOURCE = 'external-memberships';

const externalMembershipEndpoints = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getExternalMemberships: build.query<ExternalMembershipListOutputItem[], string>({
      query: (tid) => `/tid/${tid}/${RESOURCE}/`,
      providesTags: [TagType.ExternalMembership],
    }),
  }),
  overrideExisting: false,
});

export const { useGetExternalMembershipsQuery } = externalMembershipEndpoints;
