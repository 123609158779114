// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { createAssertionFunction } from '../../errors';
export var PinpointValidationErrorCode;
(function (PinpointValidationErrorCode) {
    PinpointValidationErrorCode["NoAppId"] = "NoAppId";
})(PinpointValidationErrorCode || (PinpointValidationErrorCode = {}));
const pinpointValidationErrorMap = {
    [PinpointValidationErrorCode.NoAppId]: {
        message: 'Missing application id.',
    },
};
export const assert = createAssertionFunction(pinpointValidationErrorMap);
